import { Trigger } from '@radix-ui/react-dropdown-menu'
import { ChevronDown } from 'lucide-react'
import {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ActionMeta } from 'react-select'
import { AsyncSingleSearchWithSelectProps } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Avatar, AvatarSize } from '~/core/ui/Avatar'
import { AvatarGroup, AvatarGroupProps } from '~/core/ui/AvatarGroup'
import { TextButton, TextButtonProps } from '~/core/ui/TextButton'
import {
  Button,
  buttonConfigurationSizeIconLoadingVariants,
  buttonConfigurationSpaceLabelWithIconVariants,
  buttonIconVariants,
  ButtonProps,
  fontSizeButton,
  sizeIconWithLabel
} from '~/core/ui/Button'
import { Dot, DotProps } from '~/core/ui/Dot'
import { DropdownOption, DropdownOptionProps } from '~/core/ui/DropdownOption'
import { DropdownOptionAsync } from '~/core/ui/DropdownOptionAsync'
import { CloseCircleFill } from '~/core/ui/FillIcons'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectOptionProps
} from '~/core/ui/Select'
import {
  Tooltip,
  tooltipAlignProps,
  tooltipPositionProps,
  TooltipProps
} from '~/core/ui/Tooltip'

type ComboboxSelectValue = ISelectOption | ISelectOption[] | undefined
export type ComboboxSelectPropsAsync =
  AsyncSingleSearchWithSelectProps['promiseOptions']

export interface ComboboxSelectProps {
  defaultOpenDropdown?: boolean
  renderTrigger?: ({
    onOpenChange,
    open
  }: {
    onOpenChange?: (value: boolean) => void
    open?: boolean
  }) => ReactNode
  value?: ISelectOption | ISelectOption[]
  onChange?: (
    value?: ISelectOption | ISelectOption[],
    actionMeta?: ActionMeta<ISelectOption>
  ) => void
  type?: 'default' | 'filled' | 'unstyled'
  size?: 'sm' | 'md' | 'lg'
  options?: ISelectOption[] | ComboboxSelectPropsAsync
  isSearchable?: boolean
  isClearable?: boolean
  isMulti?: boolean
  isMultiGroup?:boolean
  isLoading?: boolean
  destructive?: boolean
  countName?: string
  isDisabled?: boolean
  placeholder?: string
  menuOptionAlign?: DropdownOptionProps['align']
  menuOptionSide?: DropdownOptionProps['side']
  dropdownMenuClassName?: string //
  containerMenuClassName?: string
  buttonFontWeightClassName?: string
  buttonClassName?: string
  searchPlaceholder?: string
  loadingMessage?: string
  noOptionsMessage?: string
  portalContainer?: HTMLElement | null
  avatarToolTipPosition?: tooltipPositionProps
  toolTipPositionAvatarCount?: tooltipPositionProps
  tooltipAlignAvatarCount?: tooltipAlignProps
  configSelectOption?: SelectOptionProps
  getCustomValueRender?: (
    values: ISelectOption | ISelectOption[] | undefined
  ) => ReactElement | undefined
  customerDropdownHeader?: ReactElement
  tooltipOption?: TooltipProps
  closeOnSelect?: boolean
  hideDropdownIcon?: boolean
  isShowAvatarSingleValue?: boolean
}

const ShowTooltip = ({
  children,
  tooltipOption,
  hidden
}: PropsWithChildren<{ tooltipOption?: TooltipProps; hidden?: boolean }>) => {
  return tooltipOption ? (
    <Tooltip
      {...(!hidden || !tooltipOption ? { open: false } : {})}
      position={tooltipOption?.position}
      content={tooltipOption?.content}>
      {children}
    </Tooltip>
  ) : (
    <> {children}</>
  )
}
const ComboboxSelect = forwardRef<{}, ComboboxSelectProps>((props, ref) => {
  const [uncontrolValue, setUnControlValue] = useState<ComboboxSelectValue>(
    props.value
  )
 
  const withDefaultProp: ComboboxSelectProps = {
    ...ComboboxSelectDefaultProps,
    ...props,
    onChange: props.onChange || setUnControlValue
  }
  const value = (
    props.onChange ? withDefaultProp.value : uncontrolValue
  ) as ISelectOption

  const values: ISelectOption[] | undefined = Array.isArray(
    props.onChange ? withDefaultProp.value : uncontrolValue
  )
    ? (withDefaultProp.value as ISelectOption[]) ||
      (uncontrolValue as ISelectOption[])
    : undefined

  const buttonSize = (
    withDefaultProp.type === 'unstyled'
      ? { sm: 'sm', md: 'md', lg: 'lg' }
      : { sm: 'xs', md: 'sm', lg: 'lg' }
  )[withDefaultProp.size || 'md'] as Exclude<ButtonProps['size'], undefined>
  const avatarSize = { sm: '2xs', md: 'xs', lg: 'sm' }[
    withDefaultProp.size || 'md'
  ]
  const dotSize = { sm: 'sm', md: 'md', lg: 'lg' }[withDefaultProp.size || 'md']
  const dotGap = { sm: 'mr-1.5', md: 'mr-2', lg: 'mr-2.5' }[
    withDefaultProp.size || 'md'
  ]

  const buttonLabel = buttonConfigurationSpaceLabelWithIconVariants({
    variant: `${buttonSize}Trailing`
  })
  const iconVariant = buttonIconVariants({
    variant: `primary${
      withDefaultProp.isDisabled ? 'WithDisabled' : 'NoDisabled'
    }`
  })
  const buttonFontSize = fontSizeButton[buttonSize]
  const buttonType = {
    default: 'secondary',
    filled: 'tertiary',
    unstyled: 'unstyled'
  }[withDefaultProp.type || 'default']
  const valueRender =
    withDefaultProp.getCustomValueRender &&
    withDefaultProp.getCustomValueRender(values || value)

  const getIcon = () => {
    if (withDefaultProp.isLoading) {
      return 'loading'
    }

    if (
      withDefaultProp.isClearable &&
      (value || (values && values.length > 0))
    ) {
      return 'clearButton'
    }

    if (!!withDefaultProp.hideDropdownIcon) {
      return 'noneDropdownIcon'
    }

    return 'chevron'
  }

  const renderAvatar4SingleValue = () => {
    if (!value && !values)
      return (
        <div className={`${buttonLabel} ${buttonFontSize} text-gray-500`}>
          {withDefaultProp.placeholder}
        </div>
      )
    return (
      <AvatarGroup
        className={buttonLabel}
        size={avatarSize as AvatarGroupProps['size']}
        toolTipPosition={withDefaultProp.avatarToolTipPosition}
        toolTipPositionAvatarCount={withDefaultProp.toolTipPositionAvatarCount}
        tooltipAlignAvatarCount={withDefaultProp.tooltipAlignAvatarCount}
        source={[
          {
            src: value?.avatarVariants?.thumb?.url,
            alt: value.supportingObj?.name,
            id: Number(value.value),
            tooltip: value.supportingObj?.name,
            defaultColour: value.supportingObj?.defaultColour
          }
        ]}
      />
    )
  }

  const btnProps = {
    isDisabled: withDefaultProp.isDisabled,
    size: buttonSize,
    type: buttonType,
    fontWeight: withDefaultProp.buttonFontWeightClassName,
    children: (
      <div className="flex flex-1 items-center justify-between">
        {props?.isShowAvatarSingleValue ? (
          renderAvatar4SingleValue()
        ) : (
          <>
            {valueRender ? (
              <span className={`${buttonLabel} ${buttonFontSize}`}>
                {valueRender}
              </span>
            ) : (
              <>
                {withDefaultProp.isMulti &&
                  (values?.find((item) =>
                    Object.keys(item).includes('avatar')
                  ) ? (
                    <AvatarGroup
                      className={buttonLabel}
                      size={avatarSize as AvatarGroupProps['size']}
                      toolTipPosition={withDefaultProp.avatarToolTipPosition}
                      toolTipPositionAvatarCount={
                        withDefaultProp.toolTipPositionAvatarCount
                      }
                      tooltipAlignAvatarCount={
                        withDefaultProp.tooltipAlignAvatarCount
                      }
                      source={values.map((item) => ({
                        src: item?.avatarVariants?.thumb?.url,
                        alt: item.supportingObj?.name,
                        id: Number(item.value),
                        tooltip: item.supportingObj?.name,
                        defaultColour: item.supportingObj?.defaultColour
                      }))}
                    />
                  ) : values && values?.length > 1 ? (
                    <span className={`${buttonLabel} ${buttonFontSize}`}>
                      {values?.length} {withDefaultProp.countName}
                    </span>
                  ) : values?.length === 1 ? (
                    <span
                      className={`block overflow-hidden overflow-ellipsis whitespace-nowrap  ${buttonLabel} ${buttonFontSize}`}>
                      {values[0]?.supportingObj?.name}{' '}
                    </span>
                  ) : (
                    values && (
                      <span
                        className={`${buttonLabel} text-gray-500 ${buttonFontSize}`}>
                        {withDefaultProp.placeholder}
                      </span>
                    )
                  ))}

                {value?.avatarVariants?.thumb?.url &&
                withDefaultProp.isMulti ? (
                  <Avatar
                    color={value?.supportingObj?.defaultColour}
                    alt={value?.supportingObj?.name}
                    size={buttonSize as AvatarSize}
                    src={value?.avatarVariants?.thumb?.url}
                  />
                ) : (
                  (value?.dot || value?.supportingObj?.name) && (
                    <div
                      className={`${
                        value?.dot
                          ? 'flex flex-1 items-center space-x-2 overflow-hidden overflow-ellipsis whitespace-nowrap'
                          : 'block overflow-hidden overflow-ellipsis whitespace-nowrap'
                      } ${buttonLabel} ${buttonFontSize}`}>
                      {value?.dot && (
                        <div className={`${dotGap} flex`}>
                          <Dot
                            size={dotSize as DotProps['size']}
                            color={value.dot}
                          />
                        </div>
                      )}

                      {value?.supportingObj?.name}

                      {value?.supportingObj?.nameRequired ? <span className="font-sm text-red-500"> *</span> : null}
                    </div>
                  )
                )}
                {!value && !values && (
                  <div
                    className={`${buttonLabel} ${buttonFontSize} text-gray-500`}>
                    {withDefaultProp.placeholder}
                  </div>
                )}
              </>
            )}
          </>
        )}

        {
          {
            loading: (
              <div>
                <svg
                  aria-hidden="true"
                  className={`animate-spin text-transparent ${buttonConfigurationSizeIconLoadingVariants(
                    { size: buttonSize }
                  )} fill-gray-400`}
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ),
            clearButton: (
              <div
                onPointerDown={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  withDefaultProp?.onChange &&
                    withDefaultProp?.onChange(undefined)
                }}
                className={`${iconVariant} my-ignore-action cursor-pointer`}>
                <div className="hidden group-hover:block">
                  <CloseCircleFill size={sizeIconWithLabel[buttonSize] || 12} />
                </div>
                <div className="block group-hover:hidden">
                  <ChevronDown size={sizeIconWithLabel[buttonSize] || 12} />
                </div>
              </div>
            ),
            chevron: <ChevronDown className="flex-none" size={sizeIconWithLabel[buttonSize] || 12} />,
            noneDropdownIcon: <span className="hidden" />
          }[getIcon()]
        }
      </div>
    )
  }
  const isAsync = typeof props.options === 'function'

  const DropdownComponent = useMemo(
    () => (isAsync ? DropdownOptionAsync : DropdownOption),
    [isAsync]
  )
  const selectControl = {
    options: props.options as ISelectOption[]
  }
  const closeDropRef = useRef(() => {})
  const [searchInput, setSearchInputChange] = useState<string>()
  return (
    <DropdownComponent
      defaultOpen={props.defaultOpenDropdown}
      isCloseDropdownOnSelect={false}
      side={withDefaultProp.menuOptionSide}
      align={withDefaultProp.menuOptionAlign}
      portalContainer={props.portalContainer}
      renderTrigger={({ onOpenChange, open }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (!open) {
            setSearchInputChange('')
          }
        }, [open])
        closeDropRef.current = () => onOpenChange(false)
        return (
          <div>
            <Trigger disabled={withDefaultProp.isDisabled} asChild>
              {props?.renderTrigger ? (
                props?.renderTrigger({ onOpenChange, open })
              ) : (
                <div className="group">
                  <ShowTooltip tooltipOption={props.tooltipOption}>
                    {props.type === 'unstyled' ? (
                      <TextButton
                        onClick={() => {
                          onOpenChange(true)
                        }}
                        className={
                          withDefaultProp.isDisabled
                            ? ''
                            : `text-gray-900 ${withDefaultProp.buttonClassName}`
                        }
                        {...btnProps}
                        size={btnProps.size as TextButtonProps['size']}
                        type="secondary"
                        underline={false}
                      />
                    ) : (
                      <Button
                        {...btnProps}
                        showFocusRing={open}
                        className={`text-gray-900 ${withDefaultProp.buttonClassName}`}
                        onClick={() => {
                          onOpenChange(true)
                        }}
                        iconRootStyle={true}
                        icon="trailing"
                        type="secondary"
                      />
                    )}
                  </ShowTooltip>

                  {/* {withDefaultProp.menuOptionAlign === ""} */}
                </div>
              )}
            </Trigger>
          </div>
        )
      }}
      className="z-50"
      triggerClassName="inline-block"
      configSelect={{
        size: 'sm',
        placeholder: withDefaultProp.searchPlaceholder,
        value: props.onChange ? props.value : uncontrolValue,
        hideControl: !withDefaultProp.isSearchable,
        onChange: (newValue, actionMeta) => {
          if (props.onChange) {
            if (props.closeOnSelect) {
              closeDropRef.current()
            }
            props.onChange(
              newValue as ISelectOption | ISelectOption[] | undefined,
              actionMeta
            )
          } else {
            setUnControlValue(
              newValue as ISelectOption | ISelectOption[] | undefined
            )
          }
        },
        ...(isAsync
          ? {
              promiseOptions: props.options as ComboboxSelectPropsAsync,
              loadAsyncWhenRender: true
            }
          : selectControl),
        classNameOverride: {
          menu: props.dropdownMenuClassName || 'min-w-[160px]',
          container: props.containerMenuClassName,
          loadingMessage: withDefaultProp.loadingMessage,
          noOptionsMessage: withDefaultProp.noOptionsMessage,
        },
        detectAvatarFromOption: true,
        configSelectOption: {
          supportingText: [
            'name',
            'description',
            'descriptionHelpName',
            'shortName',
            'shortDescription'
          ],
          ...(props.configSelectOption || {}),
          ...(isAsync
            ? {}
            : {
                avatar: !!((props.options as ISelectOption[]) || []).find(
                  (item) => !!item.avatar
                )
              }),
          option: withDefaultProp.isMulti ? 'checkbox' : 'radio'
        },
        isMulti: props.isMulti,
        isMultiGroup: props.isMultiGroup,
        customerDropdownHeader: props.customerDropdownHeader,
        inputValue: searchInput,
        onInputChange: (newValue) => {
          setSearchInputChange(newValue)
        }
      }}
    />
  )
})

export const ComboboxSelectDefaultProps: ComboboxSelectProps = {
  type: 'default',
  size: 'sm',
  isSearchable: true,
  isClearable: true,
  isMulti: false,
  countName: 'selected',
  placeholder: 'select',
  menuOptionAlign: 'start',
  menuOptionSide: 'top',
  searchPlaceholder: 'Search',
  loadingMessage: 'Loading',
  noOptionsMessage: 'No Options',
  avatarToolTipPosition: 'top',
  toolTipPositionAvatarCount: 'top',
  tooltipAlignAvatarCount: 'start'
}
ComboboxSelect.defaultProps = ComboboxSelectDefaultProps
ComboboxSelect.displayName = 'ComboboxSelect'

export default ComboboxSelect
