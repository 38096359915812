'use client'

import { ReactElement, ReactNode } from 'react'
import { Avatar, AvatarSize } from '~/core/ui/Avatar'
import {
  Tooltip,
  tooltipAlignProps,
  tooltipPositionProps
} from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { AvatarHandler } from '~/core/ui/AvatarHandler'

interface AvatarGroupProps {
  size?: AvatarSize
  source?: Array<{
    id: number
    alt?: string
    src?: string
    tooltip?: ReactNode
    removable?: boolean
    defaultColour?: string
  }>
  orderAvatar?: 'leftToRight' | 'rightToLeft'
  orderPlacement?: 'leftToRight' | 'rightToLeft'
  toolTipPosition?: tooltipPositionProps
  tooltipAlign?: tooltipAlignProps
  toolTipPositionAvatarCount?: tooltipPositionProps
  tooltipAlignAvatarCount?: tooltipAlignProps
  maxUser?: number
  className?: string
  removeAvatar?: (id: number) => void
  extraControl?: ReactElement
  totalUsers?: number
  isNotShowToolTip4CountAvatar?: boolean
}

const AvatarGroup = ({
  source = [],
  size,
  toolTipPosition,
  toolTipPositionAvatarCount,
  tooltipAlignAvatarCount,
  tooltipAlign,
  orderAvatar = 'leftToRight',
  orderPlacement = 'leftToRight',
  maxUser = 3,
  className,
  removeAvatar,
  extraControl,
  totalUsers,
  isNotShowToolTip4CountAvatar = false
}: AvatarGroupProps) => {
  const toolTipCountContent = source?.slice(maxUser).map((item) => {
    if (typeof item?.tooltip !== 'string') return item.tooltip
    return <div key={`member-${item?.id}`}>{`${item?.tooltip}`}</div>
  })

  const rendered = () => {
    return (
      <>
        {isNotShowToolTip4CountAvatar && totalUsers ? (
          <>
            {totalUsers > maxUser ? (
              <Avatar
                className="hover:cursor-pointer"
                size={size}
                count={totalUsers - maxUser}
                isGroup
              />
            ) : null}
          </>
        ) : totalUsers && totalUsers > maxUser ? (
          <Tooltip
            align={tooltipAlignAvatarCount || 'start'}
            position={toolTipPositionAvatarCount || 'top'}
            content={toolTipCountContent}>
            <Avatar
              className="hover:cursor-pointer"
              size={size}
              count={totalUsers - maxUser}
              isGroup
            />
          </Tooltip>
        ) : source?.length > maxUser ? (
          <Tooltip
            align={tooltipAlignAvatarCount || 'start'}
            position={toolTipPositionAvatarCount || 'top'}
            content={toolTipCountContent}>
            <Avatar
              className="hover:cursor-pointer"
              size={size}
              count={source?.length - maxUser}
              isGroup
            />
          </Tooltip>
        ) : null}
      </>
    )
  }

  return (
    <div
      className={cn(
        'flex -space-x-2',
        orderAvatar === 'rightToLeft' ? 'flex-row-reverse space-x-reverse' : '',
        className
      )}>
      {orderPlacement === 'rightToLeft' ? (
        <>
          {extraControl}
          {rendered()}
        </>
      ) : null}
      {source?.slice(0, maxUser)?.map((item) => {
        return (
          <div key={item.id}>
            <div className="relative">
              <Tooltip
                classNameConfig={{
                  content: item?.tooltip ? '' : 'hidden'
                }}
                content={item.tooltip}
                position={toolTipPosition || 'top'}
                align={tooltipAlign || 'end'}>
                <AvatarHandler
                  size={size}
                  id={item.id}
                  removeAvatar={removeAvatar}
                  isShow={item.removable}>
                  <Avatar
                    color={item.defaultColour}
                    size={size}
                    alt={item.alt}
                    src={item?.src}
                    isGroup
                  />
                </AvatarHandler>
              </Tooltip>
            </div>
          </div>
        )
      })}
      {orderPlacement === 'leftToRight' ? (
        <>
          {rendered()}
          {extraControl}
        </>
      ) : null}
    </div>
  )
}

AvatarGroup.displayName = 'AvatarGroup'

export { AvatarGroup }
export type { AvatarGroupProps }
